<template>
  <div class="statistics-train-container">
    <div class="search-box">
      <Row>
        <Col span="24">
          <h2 class="search-title border-bottom">按条件搜索</h2>
        </Col>
      </Row>
      <Form class="search-form" :label-width="100" ref="formValidate" :model="searchForm" :rules="ruleValidate">
        <Row>
          <Col span="4">
            <Form-item label="会员姓名：">
              <Input v-model="searchForm.member_name" placeholder="请输入..."></Input>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="4">
            <Form-item label="性别：">
              <Select clearable v-model="searchForm.sex" placeholder="请选择">
                <Option :value="v.id" v-for="v in sexList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="上传时间：">
              <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="训练次数：">
              <Row>
                <Col span="10">
                  <Form-item prop="count_start">
                    <Input v-model="searchForm.count_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="count_end">
                    <Input v-model="searchForm.count_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">次</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="总消耗：">
              <Row>
                <Col span="10">
                  <Form-item prop="total_start">
                    <Input v-model="searchForm.total_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="total_end">
                    <Input v-model="searchForm.total_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">kcal</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="总训练时长：">
              <Row>
                <Col span="10">
                  <Form-item prop="time_start">
                    <Input v-model="searchForm.time_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="time_end">
                    <Input v-model="searchForm.time_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">min</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="年龄：">
              <Row>
                <Col span="10">
                  <Form-item prop="age_start">
                    <Input v-model="searchForm.age_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="age_end">
                    <Input v-model="searchForm.age_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">岁</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <div class="tac">
          <Button type="primary" class="search-btn" @click="handleSubmit('formValidate')">搜索</Button>
        </div>
      </Form>
    </div>
    <div class="export-div clearfix">
      <a href="javascript:;" class="export-btn" @click="exportData()" v-if="this.power.indexOf('数据统计--数据导出权限') > -1">导出数据</a>
    </div>
    <Table stripe :columns="columns" :data="trainData" @on-selection-change="selectOne" ref="table"></Table>
    <Row>
      <Col span="12">
        <div class="table-oprate">
          <!--<Button @click="">删除</Button>-->
        </div>
      </Col>
      <Col span="12">
        <span class="records">共{{pageTotal}}条记录</span>
      </Col>
    </Row>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>
    <Modal
      :title="`心率曲线 - ${modalTitle}`"
      v-model="showModal"
      class-name="vertical-center-modal"
      width="800"
    >
      <div class="modal-chart" ref="chartRef" style="height: 300px;"></div>
      <div slot="footer">
        <Button type="primary" @click.native="showModal = false">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';
import warningService from '@/services/warningService';
import statisticsService from '@/services/statisticsService';
import huaweiService from '@/services/huaweiService';

export default {
	data() {
		const countSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.count_end !== '') {
					this.$refs.formValidate.validateField('count_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.count_end !== '') {
						this.$refs.formValidate.validateField('count_end');
					}
					callback();
				}
			}
		};
		const countLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.count_start !== '' &&
						Number(value) < Number(this.searchForm.count_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const totalSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.total_end !== '') {
					this.$refs.formValidate.validateField('total_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.total_end !== '') {
						this.$refs.formValidate.validateField('total_end');
					}
					callback();
				}
			}
		};
		const totalLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.total_start !== '' &&
						Number(value) < Number(this.searchForm.total_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const timeSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.time_end !== '') {
					this.$refs.formValidate.validateField('time_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.time_end !== '') {
						this.$refs.formValidate.validateField('time_end');
					}
					callback();
				}
			}
		};
		const timeLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.time_start !== '' &&
						Number(value) < Number(this.searchForm.time_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const ageSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.age_end !== '') {
					this.$refs.formValidate.validateField('age_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.age_end !== '') {
						this.$refs.formValidate.validateField('age_end');
					}
					callback();
				}
			}
		};
		const ageLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.age_start !== '' &&
						Number(value) < Number(this.searchForm.age_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		return {
			searchForm: {
				page: 1,
				size: 10,
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				start: '',
				end: '',
				count_start: '',
				count_end: '',
				total_start: '',
				total_end: '',
				time_start: '',
				time_end: '',
				age_start: '',
				age_end: '',
			},
			exportForm: {
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				start: '',
				end: '',
				count_start: '',
				count_end: '',
				total_start: '',
				total_end: '',
				time_start: '',
				time_end: '',
				age_start: '',
				age_end: '',
			},
			sexList: [
				{
					id: 0,
					name: '男',
				},
				{
					id: 1,
					name: '女',
				},
			],
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			columns: [
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					width: '100px',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.trainData[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{
					title: '性别',
					key: 'sex',
					align: 'center',
					width: '80px',
					render: (h, params) => {
						if (params.row.sex === 0) {
							return h('div', '男');
						} else if (params.row.sex === 1) {
							return h('div', '女');
						} else {
							return h('div', params.row.sex);
						}
					},
				},
				{
					title: '年龄',
					key: 'age',
					align: 'center',
					width: '80px',
					render: (h, params) => {
						if (params.row.age === null) {
							return h('div', params.row.age);
						} else {
							return h('div', params.row.age + '岁');
						}
					},
				},
				{ title: '训练项目', key: 'xlxm', align: 'center' },
				{ title: '开始时间', key: 'start', align: 'center' },
				{
					title: '总消耗能量',
					key: 'total',
					align: 'center',
					width: '110px',
					render: (h, params) => {
						if (params.row.total === null) {
							return h('div', params.row.total);
						} else {
							return h('div', params.row.total + 'kcal');
						}
					},
				},
				{
					title: '总训练次数',
					key: 'count',
					align: 'center',
					width: '110px',
					render: (h, params) => {
						if (params.row.total_effectivege === null) {
							return h('div', params.row.count);
						} else {
							return h('div', params.row.count + '次');
						}
					},
				},
				{
					title: '总训练时长',
					key: 'time',
					align: 'center',
					width: '110px',
					render: (h, params) => {
						if (params.row.total_time === null) {
							return h('div', params.row.time);
						} else {
							return h('div', params.row.time + 'min');
						}
					},
				},
				{ title: '平均心率', key: 'pjxl', align: 'center', width: '100px' },
				{ title: '最大心率', key: 'zdxl', align: 'center', width: '100px' },
				{
					title: '心率曲线',
					align: 'center',
					align: 'center',
					width: '100px',
					render: (h, params) => {
						return h('a', {
							class: 'icon_eye',
							style: { marginRight: '0' },
							on: {
								click: () => {
									const { id, member_name } = params.row;
									this.showModal = true;
									this.modalTitle = member_name;
									huaweiService.getHeartRate({ jl_id: id }).then((data) => {
										echarts.init(this.$refs.chartRef).setOption({
											color: ['#fdad00'],
											grid: {
												top: 20,
												right: 20,
												bottom: 20,
												left: 40,
											},
											tooltip: { trigger: 'axis' },
											xAxis: {
												type: 'category',
												data: data.heart_x,
											},
											yAxis: { type: 'value' },
											series: [
												{
													data: data.heart_y,
													type: 'line',
													smooth: true,
												},
											],
										});
									});
								},
							},
						});
					},
				},
				{ title: '上传时间', key: 'create_time', align: 'center' },
			],
			trainData: [],
			pageTotal: 0,
			ruleValidate: {
				count_start: [{ validator: countSmall, trigger: 'blur' }],
				count_end: [{ validator: countLarge, trigger: 'blur' }],
				total_start: [{ validator: totalSmall, trigger: 'blur' }],
				total_end: [{ validator: totalLarge, trigger: 'blur' }],
				time_start: [{ validator: timeSmall, trigger: 'blur' }],
				time_end: [{ validator: timeLarge, trigger: 'blur' }],
				age_start: [{ validator: ageSmall, trigger: 'blur' }],
				age_end: [{ validator: ageLarge, trigger: 'blur' }],
			},
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
			showModal: false,
			modalTitle: '',
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getTrainData();
		warningService.getWarningSelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getTrainData() {
			statisticsService.getTrainData(this.searchForm).then((data) => {
				this.trainData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleSubmit(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					this.searchForm.page = 1;
					this.getTrainData();
					this.exportForm.member_name = this.searchForm.member_name;
					this.exportForm.sex = this.searchForm.sex;
					this.exportForm.pid = this.searchForm.pid;
					this.exportForm.cid = this.searchForm.cid;
					this.exportForm.sid = this.searchForm.sid;
					this.exportForm.start = this.searchForm.start;
					this.exportForm.end = this.searchForm.end || '';
					this.exportForm.count_start = this.searchForm.count_start;
					this.exportForm.count_end = this.searchForm.count_end;
					this.exportForm.total_start = this.searchForm.total_start;
					this.exportForm.total_end = this.searchForm.total_end;
					this.exportForm.time_start = this.searchForm.time_start;
					this.exportForm.time_end = this.searchForm.time_end;
					this.exportForm.age_start = this.searchForm.age_start;
					this.exportForm.age_end = this.searchForm.age_end;
				}
			});
		},
		changeTime(date) {
			this.searchForm.start = date[0];
			this.searchForm.end = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getTrainData();
		},
		selectOne() {},
		exportData() {
			location.href =
				'http://conlife.sskh.net:9928/api/train/export?member_name=' +
				this.exportForm.member_name +
				'&sex=' +
				this.exportForm.sex +
				'&pid=' +
				this.exportForm.pid +
				'&cid=' +
				this.exportForm.cid +
				'&sid=' +
				this.exportForm.sid +
				'&start=' +
				this.exportForm.start +
				'&end=' +
				this.exportForm.end +
				'&count_start=' +
				this.exportForm.count_start +
				'&count_end=' +
				this.exportForm.count_end +
				'&total_start=' +
				this.exportForm.total_start +
				'&total_end=' +
				this.exportForm.total_end +
				'&time_start=' +
				this.exportForm.time_start +
				'&time_end=' +
				this.exportForm.time_end +
				'&age_start=' +
				this.exportForm.age_start +
				'&age_end=' +
				this.exportForm.age_end;
		},
	},
};
</script>

<style lang="css" scoped>
.statistics-train-container .search-box {
  border: 1px solid #d7dde4;
}
</style>
